import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItemsPage from '../components/ItemsPage';
import { itemCategories } from '../constants/ItemCategories';

class Documents extends Component {
	render() {
		return (
			<ItemsPage
				title="My Pay"

				unreadTabText="Unread"
				readTabText="Read"

				unread={this.props.unread}
				read={this.props.read}

				unreadItemTypes={this.props.unreadItemTypes}
				readItemTypes={this.props.readItemTypes}

				unreadItemCategory={this.props.unreadItemCategory}
				readItemCategory={this.props.readItemCategory}
			/>
		);
	}
}

export default connect(
	state => ({
		unread: state.unreadPay,
		read: state.readPay,

		unreadItemTypes: state.unreadPayTypes,
		readItemTypes: state.readPayTypes,

		unreadItemCategory: itemCategories.unreadPay, 
		readItemCategory: itemCategories.readPay 
	})
)(Documents);