import React, { Component } from 'react';
import { Button, FormGroup, FormControl, FormLabel, Col } from "react-bootstrap";
import { bindActionCreators } from 'redux';
import { history } from '../history';
import { connect } from 'react-redux';
import { userActions } from '../store/Authentication';
import Loading from '../components/Loading';
import AlertPanel from '../components/AlertPanel';

class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			employeeCode: '',
			nicNumber: '',
			dateOfBirth: ''
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleBackToLogin = this.handleBackToLogin.bind(this);
	}

	validateForm() {
		return this.state.employeeCode.length > 0
			&& this.state.nicNumber.length > 0
			&& this.state.dateOfBirth.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	handleLogin = event => {
		event.preventDefault();
		this.props.forgotPassword(this.state.employeeCode, this.state.nicNumber, this.state.dateOfBirth);
	}

	handleBackToLogin = event => {
		event.preventDefault();
		history.push('/login');
	}

	render() {
		const { authentication } = this.props;
		return (
			<Col sm={{ span: 6, offset: 3 }}>
				<div className="login-logo">
					<img src="login-logo.png" alt="Employee Portal Logo" />
				</div>
				<h1>Log In</h1>
				<div>
					{authentication.message ? <AlertPanel type="danger" message={authentication.message} /> : []}
					{authentication.error ? <AlertPanel type="danger" message={authentication.error} /> : []}

					{authentication.loggingIn ? <Loading /> : []}
					{!authentication.loggingIn ?
						<form onSubmit={this.handleLogin}>
							<FormGroup controlId="employeeCode" bsSize="large">
								<FormLabel>Employee code</FormLabel>
								<FormControl
									autoFocus
									type="text"
									value={this.state.employeeCode}
									onChange={this.handleChange}
								/>
							</FormGroup>
							<FormGroup controlId="nicNumber" bsSize="large">
								<FormLabel>NI number</FormLabel>
								<FormControl
									type="text"
									value={this.state.nicNumber}
									onChange={this.handleChange}
								/>
							</FormGroup>
							<FormGroup controlId="dateOfBirth" bsSize="large">
								<FormLabel>Date of birth</FormLabel>
								<FormControl
									type="date"
									value={this.state.dateOfBirth}
									onChange={this.handleChange}
								/>
							</FormGroup>

							<br />
              <Button
                className="btn btn-default"
								block
								bsSize="large"
								disabled={!this.validateForm()}
								type="submit"
							>
								Log In
							</Button>
							<br/>
              <Button
                className="btn btn-default"
								block
								className="secondary"
								onClick={this.handleBackToLogin}
							>
								Cancel
							</Button>
						</form>
					: []}
				</div>
			</Col>
		);
	}
}

export default connect(
	state => ({ authentication: state.authentication, alerts: state.alerts.alerts }),
	dispatch => bindActionCreators(userActions, dispatch)
)(ForgotPassword);