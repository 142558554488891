import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../history';
import ItemsPage from '../components/ItemsPage';
import { itemCategories } from '../constants/ItemCategories';

class MyRequests extends Component {
	constructor(props) {
		super(props);
		this.handleNewClick = this.handleNewClick.bind(this);
	}

	handleNewClick() {
		history.push(`/myrequests/detail/0`);
	}


	render() {
		return (
			<ItemsPage
				title="My Requests"

				unreadTabText="Future"
				readTabText="History"

				unread={this.props.unread}
				read={this.props.read}

				unreadItemTypes={this.props.unreadItemTypes}
				readItemTypes={this.props.readItemTypes}

				unreadItemCategory={this.props.unreadItemCategory}
				readItemCategory={this.props.readItemCategory}

				showNewButton={true}
				newButtonText="New Request"
				onNewClick={this.handleNewClick}
			/>
		);
	}
}

export default connect(
	state => ({
		unread: state.unreadRequests,
		read: state.readRequests,

		unreadItemTypes: state.unreadRequestTypes,
		readItemTypes: state.readRequestTypes,

		unreadItemCategory: itemCategories.unreadRequests,
		readItemCategory: itemCategories.readRequests
	})
)(MyRequests);