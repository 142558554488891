import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItemsPage from '../components/ItemsPage';
import { itemCategories } from '../constants/ItemCategories';

class Notices extends Component {
	render() {
		return (
			<ItemsPage
				title="Notices"

				unreadTabText="Unread"
				readTabText="Read"

				unread={this.props.unread}
				read={this.props.read}

				unreadItemTypes={this.props.unreadItemTypes}
				readItemTypes={this.props.readItemTypes}

				unreadItemCategory={this.props.unreadItemCategory}
				readItemCategory={this.props.readItemCategory}
			/>
		);
	}
}

export default connect(
	state => ({
		unread: state.unreadNotices,
		read: state.readNotices,

		unreadItemTypes: state.unreadNoticeTypes,
		readItemTypes: state.readNoticeTypes,

		unreadItemCategory: itemCategories.unreadNotices,
		readItemCategory: itemCategories.readNotices
	})
)(Notices);