import { fetchActions } from "../FetchWrapper";
import { userActions } from './Authentication';

const planRequestType = 'PLAN_REQUEST';
const planSuccessType = 'PLAN_SUCCESS';
const planFailureType = 'PLAN_FAILURE';

const initialState = {
	items: [],
	isLoading: false,
	canRequest: true,
	error: undefined
};

export const planActions = {
	get,
	refresh
};

function get() {
	return (dispatch, getState) => {
		if (!getState().myPlan.canRequest)
			return;

		dispatch(refresh());
	};
}

function refresh() {
	return (dispatch, getState) => {

		if (!getState().clientConfiguration.isLoaded)
			return;

		if (!getState().clientConfiguration.config.endpoint && !getState().authentication.user)
			return;

		dispatch(_request());

		const url = getState().clientConfiguration.config.endpoint + "/plans";
		const ticket = getState().authentication.user.ticket;

		fetchActions.get(url, ticket)
			.then(
				response => {
					dispatch(_success(response.data));
				},
				error => {
					if (error.sessionExpired) {
						dispatch(userActions.sessionExpired());
					}
					else {
						var message = `Could not get my plan: ${error.message}`;
						dispatch(_failure(message));
					}
				}
			);
	};

	function _request() { return { type: planRequestType }; }
	function _success(items) { return { type: planSuccessType, items }; }
	function _failure(error) { return { type: planFailureType, error }; }
}

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === planRequestType) {
		return { ...state, isLoading: true, canRequest: false };
	}

	if (action.type === planSuccessType) {
		return { ...state, isLoading: false, canRequest: false, items: action.items };
	}

	if (action.type === planFailureType) {
		return { ...state, isLoading: false, canRequest: true, error: action.error};
	}

	return state;
};