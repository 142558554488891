const alertSuccessType = 'ALERT_SUCCESS';
const alertErrorType = 'ALERT_ERROR';
const alertClearType = 'ALERT_CLEAR';

const initialState = {
	alerts: [] 
};

export const alertActions = {
	success,
	error,
	clear
};

function success(message) {
	return { type: alertSuccessType, alert: { type: 'alert-success', message: message } };
}

function error(message) {
	return { type: alertErrorType, alert: { type: 'alert-danger', message: message } };
}

function clear() {
	return { type: alertClearType };
}


export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === alertSuccessType) {
		return { ...state, alerts: [...state.alerts, action.alert] };
	}

	if (action.type === alertErrorType) {
		return { ...state, alerts: [...state.alerts, action.alert] };
	}

	if (action.type === alertClearType) {
		return { ...state, alerts: [] };
	}

	return state;
};