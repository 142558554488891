import React from 'react';
import './css/Loading.css';

export default () => (
	<div className="loading-anchor">
		<div className="loading-container">
			<img className="loading-spinner" src="spinner.png" alt="Loading"/>
			<span className="loading-text">Loading...</span>
		</div>
	</div>
);
