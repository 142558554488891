import React from 'react';
import PropTypes from 'prop-types';
import './css/Header.css';

const Header = props => (
	<div className="header">
		<h1>{props.title}</h1>
		{props.children}
	</div>
);

Header.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.any
};

export default Header;