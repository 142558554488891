const printViewShow = 'ALERT_SUCCESS';
const printViewHide = 'ALERT_CLEAR';

const initialState = {
	item: undefined
};

export const printViewActions = {
	show,
	hide
};

function show(item) {
	return { type: printViewShow, item };
}

function hide() {
	return { type: printViewHide };
}


export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === printViewShow) {
		return { ...state, item: action.item };
	}

	if (action.type === printViewHide) {
		return { ...state, item: undefined };
	}

	return state;
};