import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import { history } from '../history';
import Header from '../components/Header';
import AlertPanel from '../components/AlertPanel';

class PasswordChanged extends Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit = event => {
		event.preventDefault();

		// continue
		history.push('/');
	}

	render() {
		return (
			<div>
				<Header title="Change Password" />
				<AlertPanel type="success" message="Your password has been changed." />
				<Col sm={{ span: 6, offset: 3 }}>
					<div>
						<form onSubmit={this.handleSubmit}>
              <Button
                className="btn btn-default"
								block
								bsSize="large"
								type="submit"
							>
							Continue
							</Button>
						</form>
					</div>
				</Col>
			</div>
		);
	}
}

export default connect()(PasswordChanged);