import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataFormField from './DataFormField';
import DataFormImage from './DataFormImage';
import { dataFormDataTypes } from '../constants/DataFormDataTypes'
import { faBorderNone } from '@fortawesome/free-solid-svg-icons';


class DataFormRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        //different behaviour handling based on certain data types
        let showMultiLineAnswerText = false;
        let showImage = false;
        let showField = true;
        let sectionHeading = false;

        switch (this.props.item.dataType) {
            case dataFormDataTypes.none:
                //no field for labels
                showField = false;
                break;
            case dataFormDataTypes.multiline_text:
                //show answer text? - only if can't edit & for certain data types
                showMultiLineAnswerText = !this.props.canEdit;
                break;
            case dataFormDataTypes.image:
            case dataFormDataTypes.signature_box:
                //show image
                showImage = true;
                break;
            case dataFormDataTypes.section_heading:
                //section heading row
                sectionHeading = true;
                break;
            default:
        }
        showField = showField && !showMultiLineAnswerText && !showImage ;

        return (
            <div key={`div-${this.props.item.LineNumber}`}>
                {!sectionHeading
                    ?
                    <Row className={"button-tray top"} key={this.props.item.LineNumber}>
                        <Col sm={3}>
                            {this.props.item.lineText.split("\n").map((lineText, k) => {
                                return (
                                    <Row key={`row-label-${k}`}>
                                        {/* applying the class name here gets the css applied to the label properly when not directly within a column*/}
                                        <label className="col-sm-9" key={`label-label-${k}`}>{lineText}</label>
                                    </Row>
                                )
                            })}
                        </Col>
                        <Col sm={9}>
                            {/* show multi-line answer text? */
                                showMultiLineAnswerText ?
                                this.props.item.answer.split("\n").map((answer, k) => {
                                    return (
                                        <Row key={`row-answer-${k}`}>
                                            {/* applying the class name here gets the css applied to the label properly when not directly within a column*/}
                                            <label className="col-sm-9" key={`label-answer-${k}`}>{answer}</label>
                                        </Row>
                                    )
                                })
                                    : []}
                            {/* show image */
                                showImage ?
                                    <DataFormImage
                                        item={this.props.item}
                                        editing={this.props.canEdit}
                                        changed={this.props.handleFieldChanged} />
                                    : []}
                            {/* show field */
                                showField ?
                                <DataFormField item={this.props.item}
                                    key={this.props.item.lineNumber}
                                    editing={this.props.canEdit}
                                    changed={this.props.handleFieldChanged}
                                />
                                : []}
                            </Col>
                        </Row>
                    :
                    /* section heading spans columns*/
                    <Row className={"button-tray top"} key={this.props.item.LineNumber}>
                        <Col sm={12}>
                            {this.props.item.lineText.split("\n").map((lineText, k) => {
                                return (
                                    <Row key={`row-section-heading-${k}`}>
                                        {/* applying the class name here gets the css applied to the label properly when not directly within a column*/}
                                        <label className="col-sm-12" key={`label-section-heading-${k}`}>{lineText}</label>
                                    </Row>
                                )
                            })}
                        </Col>
                    </Row>
                    }
            </div>
        );
    }
}

export default DataFormRow;
