import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DataFormButton = props => (
	<div className={`button-tray ${props.location}`}>
		{/*{!props.editing && <button className="btn btn-default" disabled={props.isLoading} onClick={props.edit}>Edit</button>}*/}
		{/*{props.editing &&*/}
		{/*	<div>*/}
		{/*	<button className="btn btn-success" disabled={props.isLoading || props.isBlockSave} onClick={props.save}>Save</button>*/}
		{/*	<button className="btn btn-default" disabled={props.isLoading} onClick={props.cancel}>Cancel</button>*/}
		{/*	</div>*/}
		{/*}*/}
		<button className="btn btn-default" onClick={props.handlePrev}><FontAwesomeIcon icon="chevron-left" /> {props.previousText}</button>
		{props.canEdit ? <button className="btn btn-default float-right" onClick={props.handleComplete}>Complete</button> : []}
		{props.canEdit ? <button className="btn btn-default float-right" onClick={props.handleSave}>Save</button> : []}
	</div>
);

export default DataFormButton;