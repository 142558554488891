import React from 'react';
import PropTypes from 'prop-types';

const DetailButton = props => (
	<div className={`button-tray ${props.location}`}>
		{!props.editing && <button className="btn btn-default" disabled={props.isLoading} onClick={props.edit}>Edit</button>}
		{props.editing &&
			<div>
			<button className="btn btn-success" disabled={props.isLoading || props.isBlockSave} onClick={props.save}>Save</button>
			<button className="btn btn-default" disabled={props.isLoading} onClick={props.cancel}>Cancel</button>
			</div>
		}
	</div>
);

DetailButton.propTypes = {
	location: PropTypes.string.isRequired,
	edit: PropTypes.func.isRequired,
	save: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default DetailButton;