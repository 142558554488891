import React, { Component } from 'react';
import { Button, FormGroup, FormControl, FormLabel, Col } from "react-bootstrap";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActions } from '../store/Authentication';
import Loading from '../components/Loading';
import AlertPanel from '../components/AlertPanel';

class Authenticate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			employeeCode: '',
			password: '',
			accessCode: ''
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleAuthenticate = this.handleAuthenticate.bind(this);
	}

	validateForm() {
		return this.state.accessCode.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	handleAuthenticate = event => {
		event.preventDefault();
		this.props.authenticate(this.state.accessCode);
	}

	render() {
		const { authentication } = this.props;
		return (
			<Col sm={{ span: 6, offset: 3 }}>
				<div className="login-logo">
					<img src="login-logo.png" alt="Employee Portal Logo" />
				</div>
				<h1>Log In</h1>
				<div>
					{authentication.message ? <AlertPanel type="danger" message={authentication.message} /> : []}
					{authentication.error ? <AlertPanel type="danger" message={authentication.error} /> : []}

					{authentication.loggingIn ? <Loading /> : []}
					{!authentication.loggingIn ?
						<form onSubmit={this.handleAuthenticate}>

							<AlertPanel type="info" message="An email PIN has been sent to you, please enter it below to sign in." /> 

							<FormGroup controlId="accessCode" bsSize="large">
								<FormLabel>PIN</FormLabel>
								<FormControl
									autoFocus
									type="text"
									value={this.state.accessCode}
									onChange={this.handleChange}
								/>
							</FormGroup>
              <Button
                className="btn btn-default"
								block
								bsSize="large"
								disabled={!this.validateForm()}
								type="submit"
							>
								Continue
							</Button>
						</form>
					: []}
				</div>
			</Col>
		);
	}
}

export default connect(
	state => ({ authentication: state.authentication, alerts: state.alerts.alerts }),
	dispatch => bindActionCreators(userActions, dispatch)
)(Authenticate);