export const fetchActions = {
	get,
	post
};


function get(url, ticket, data) {
	return __fetch(url, "GET", ticket, data);
}

function post(url, ticket, data) {
	return __fetch(url, "POST", ticket, data);
}


function __fetch(url, method, ticket, data) {
	const requestOptions = {
		method: method,
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=utf-8"
		}
	};

	if (data) {
		requestOptions.body = JSON.stringify(data);
	}

	if (ticket) {
		requestOptions.headers["tep-ticket"] = ticket;
	}

	return fetch(url, requestOptions)
		.then(handleResponse)
		.then(data => {
			return data;
		});
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);

		if (!response.ok) {
			const error = getErrorFromResponse(data, response.statusText);
			return Promise.reject(error);
		}
		return {
			data: data,
			headers: response.headers
		};
	});
}

export function getErrorFromResponse(data, response) {

	var error = { sessionExpired: false };
	if (typeof data === "object") {
		var keys = Object.keys(data);
		if (keys.length !== 0) {
			error.message = data[keys[0]];
		}
	}
	if (error.message && error.message.toLowerCase() === "invalid session") {
		error.sessionExpired = true;
	}

	if (error.message === undefined) {
		error.message = data.toString() || response.text();
	}
	return error;
}