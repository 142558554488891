const requestClientConfigType = 'REQUEST_CLIENT_CONFIG';
const receiveClientConfigType = 'RECEIVE_CLIENT_CONFIG';
const initialState = { config: {}, isLoading: false, isLoaded: false };

export const configActions = {
	requestClientConfig: () => async (dispatch, getState) => {
		if (getState().config) {
			// Don't issue a duplicate request (we already have or are loading the requested data)
			return;
		}

		dispatch({ type: requestClientConfigType });

		const url = "ClientConfiguration";
		const response = await fetch(url);
		const config = await response.json();

		dispatch({ type: receiveClientConfigType, config });
	}
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === requestClientConfigType) {
		return {
			...state,
			isLoading: true,
			isLoaded: false
		};
	}

	if (action.type === receiveClientConfigType) {
		return {
			...state,
			config: action.config,
			isLoading: false,
			isLoaded: true
		};
	}

	return state;
};
