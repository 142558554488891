import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './css/TileIcon.css';

class TileIcon extends Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick = event => {
		event.preventDefault();
		if (!this.props.disabled && typeof this.props.onSelected === "function") {
			this.props.onSelected(this.props.id);
		}
	}

	decimalToHexString = number => {
		if (number === undefined)
			return "#FFF";

		if (number < 0) {
			number = 0xFFFFFFFF + number + 1;
		}
		var hex = "#" + ("000000" + number.toString(16).toUpperCase()).substr(-6);
		return hex;
	}

	render() {
		return (
			<div
				className={`tile-icon ${this.props.className}`}
				title={this.props.title}
				onClick={this.handleClick}
				style={{ borderColor: this.decimalToHexString(this.props.borderColour), backgroundColor: this.decimalToHexString(this.props.backColour) }}
			>
				<span style={{ color: this.decimalToHexString(this.props.textColour) }} >{this.props.tileText}</span>
			</div>
		);
	}
}

TileIcon.propTypes = {
	id: PropTypes.number,
	backColour: PropTypes.number.isRequired,
	textColour: PropTypes.number.isRequired,
	borderColour: PropTypes.number.isRequired,
	tileText: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	onSelected: PropTypes.func
};

export default connect()(TileIcon);
